/* Cambia el scroll general */
::-webkit-scrollbar{
    width: 0px;
}

/* Cambia el color de fondo del scrollbar */
::-webkit-scrollbar {
    background-color: #f1f1f1; /* Cambia el color de fondo */
}

/* Cambia el color de la pista del scrollbar (la barra gris detrás del thumb) */
::-webkit-scrollbar-track {
    background-color: #F5F5F5;
}

/* Cambia el color del thumb (la barra que puedes arrastrar) */
::-webkit-scrollbar-thumb {
    background-color: #4A5568;
    border-radius: 6px;
};

/* Cambia el color del thumb al pasar el mouse sobre él */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Cambia el color del thumb al pasar el mouse sobre él */
}

