.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #4FD1C5;
    border-radius: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }